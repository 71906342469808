export default {
  data() {
    return {
      popUpLoader: false,
      popupType: null, // Stores which popup is open
      popupKey: 0,
      bulkUpdateDialgVisible: false,
      downloadDocuments: false,
      popupProps: {}, // Stores dynamic props
      popNames: ["EntityFilter", "Sort", "Fetch", "Notification", "Import", "Export"],
      popups: {
        EntityFilter: () => import("@/components/entity/v2/entityFilters.vue"),
        CustomComponentTable: () => import("@/components/customDashboard/customDashboardTable.vue"),
        BulkUpDate : () => import ("@/components/entity/v2/BulkUpdate.vue"),
        FileDownLoad : () => import ("@/components/entity/v2/FilesDownload.vue"),
        FileUploadToAI : () => import ("@/components/entity/v2/FileUploadToAI.vue"),
        WAConfig: () => import("@/components/entity/v2/WANotification.vue"),
        EmailConfig: () => import("@/components/entity/v2/EmailNotification.vue"),
        GalleryModal: () => import("@/components/templates/formComponentsExecute/GalleryModel.vue"),
        MapPreview : () => import("@/components/entity/v2/maps.vue"),
        Settings : () => import("../components/entity/v2/settings.vue"),

        // Sort: () => import("@/components/entity/v2/sort.vue"),
        // Fetch: () => import("@/components/entity/v2/fetch.vue"),
        // Notification: () => import("@/components/entity/v2/notification.vue"),
        // Import: () => import("@/components/entity/v2/import.vue"),
        // Export: () => import("@/components/entity/v2/export.vue"),
      },
    };
  },

  computed: {
    popupComponent() {
      return this.popupType ? this.popups[this.popupType] : null;
    }
  },
  methods: {
    async openPopup(type, props = {}) {
      // this.popUpLoader=true;
      //   this.popupType = type;
      //   this.popupProps = props;
      //   this.popUpLoader=false;


      this.popUpLoader = true;
      this.popupType = null; // Reset previous popup

      try {
        await this.popups[type](); // Preload the component
        this.popupType = type;
        this.popupProps = props;
        this.popupKey++; // ✅ Change key to force re-render
      } catch (error) {
        console.error("Error loading popup:", error);
      }

      this.popUpLoader = false;
    },
    closePopup(type = '') {
      this.popUpLoader = true;
      console.log("Close Action On", type)
      this.popupType = null;
      this.popupProps = {};
      this.popupKey++; // ✅ Change key to force destruction
      this.popUpLoader = false;
    },
    closeBulkUpdateDialogue() {
      this.bulkUpdateData = [
        {
          field: "",
          value: "",
        },
      ];
      this.bulkUpdateFields = [];
      this.bulkUpdateDialgVisible = false;
    },
    closeDownloadDocuments() {
      this.downloadDocuments = false;
    },
  },
};
